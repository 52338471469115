





































































import { Observer } from "mobx-vue";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { SummonViewModel } from "../viewmodels/summon-viewmodel";

@Observer
@Component({
  components: {
    DragonAvatar: () => import("@/components/dragon-avatar.vue"),
  },
})
export default class SummonPage extends Vue {
  @Inject() vm!: SummonViewModel;
  @Prop({ type: Boolean }) failed!: boolean;
}
